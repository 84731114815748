<template>
  <div>
    <a-modal
        v-model="isShow"
        centered
        :mask-closable="false"
        :ok-button-props="{ props: { loading: submitting } }"
        title="修改出殡仪式时间"
        width="600px"
        @ok="handleSubmit"
    >
      <a-form
          class="custom-compact-form"
          :form="form"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 15 }"
          @submit="handleSubmit"
      >
        <a-form-item label="出殡仪式时间">
          <a-date-picker
              style="width: 100%;"
              format="YYYY-MM-DD HH:mm"
              :show-time="{ format: 'HH:mm' }"
              placeholder="请选择时间"
              v-decorator="['funeral_time', {
              rules: [
                { required: true, message: `请选择出殡时间` }
              ],
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import {findFuneralTimeForm, updateFuneralTime} from "@/api/order";
import {formatMinuteTime} from "@/utils/time";

export default {
  name: "UpdateFuneralTime",
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'update_funeral_time' }),
      submitting: false,
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFormData()
  },
  methods: {
    fetchFormData() {
      findFuneralTimeForm(this.serviceOrderId, {}).then((res) => {
        this.form.setFieldsValue({
          funeral_time: res.data.funeral_time,
        })
      })
    },

    closeModal() {
      this.isShow = false
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          values.funeral_time = formatMinuteTime(values.funeral_time)
          updateFuneralTime(this.serviceOrderId, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
